<template>
  <div class="personnelStructure">
    <div class="search">
      <div class="count">
        <p>
          企业管理账号:<span>{{ telephone }}</span>
        </p>
        <el-button @click="changeFn" size="mini" style="margin: 0 10px"
          >变更</el-button
        >
        <p>
          企业子账号数量：<span>{{ tableData.total }}</span>
        </p>
      </div>
      <div class="search-input">
        <el-input
          prefix-icon="el-icon-search"
          placeholder="请输入内容"
          v-model="search.keyWord"
          @keyup.enter.native="searchFn"
        />
        <el-button class="hy-btn" @click="searchFn">查询</el-button>
        <el-button class="hy-btn" @click="addUser">添加员工</el-button>
      </div>
    </div>
    <div class="table-list">
      <el-table
        :data="tableData.list"
        :header-cell-style="headerCellStyle"
        style="width: 100%; text-align: center"
      >
        <el-table-column prop="telephone" label="账号"> </el-table-column>
        <el-table-column prop="realName" label="姓名"> </el-table-column>
        <el-table-column prop="title" label="岗位"> </el-table-column>
        <el-table-column prop="updateTime" width="180" label="更新时间">
        </el-table-column>
        <el-table-column prop="enableStatus" label="启用" width="80">
          <template slot-scope="scope">
            <el-switch
              @change="OffFn(scope.row)"
              v-model="scope.row.enableStatus"
              :active-value="0"
              :inactive-value="1"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" class="op" width="250px">
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
              "
            >
              <el-button
                size="mini"
                type="primary"
                plain
                @click="setPower(scope.row)"
                >设置权限</el-button
              >
              <el-button
                size="mini"
                type="primary"
                plain
                @click="editUser(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                plain
                @click="remove(scope.row)"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="primary"
                plain
                @click="handleSmsDialog(scope.row)"
                style="margin-top: 5px"
                >设置短信通知</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :total="tableData.total"
        :page-size="search.pageSize"
        :current-page.sync="search.pageNum"
      >
      </el-pagination>
    </div>
    <SmsDialog
      :isShow.sync="smsDialogShow"
      :smsDialogData="smsDialogData"
      :noticeObj="noticeObj"
      @changeOver="getList"
    ></SmsDialog>
    <InputPasswordDialog
      :isShow="passWordIsShow"
      @personnelStructure="personnelStructure"
      @close="closeDialog('passWordIsShow')"
      title="输入管理员密码"
    />
    <AddDialog
      :isShow="addUserDialogIsShow"
      :data="userDialogData"
      @close="closeDialog('addUserDialogIsShow')"
    />
    <Power
      :isShow="powerDialogIsShow"
      @close="closeDialog('powerDialogIsShow')"
      :data="powerDialogData"
    />

    <el-dialog
      title="企业变更账号"
      center
      width="500px"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-form-item label="新账号:" :label-width="formLabelWidth">
          <el-input
            maxlength="11"
            placeholder="请输入手机号"
            style="width: 224px; margin-left: 10px"
            v-model="form.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码：" prop="code" class="pr">
          <el-input
            style="width: 224px"
            placeholder="请输入验证码"
            v-model="form.code"
            maxlength="6"
          ></el-input>
          <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
            <span v-show="show">获取验证码</span>
            <span v-show="!show" class="count">{{ count }} s</span>
          </button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibleClcik"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  reqUserList,
  reqEnableOrDeleteUser,
  getEnterpriseByEnterpriseId,
  reqCheckPassword,
  reqSendCode,
  updateEnterpriseAccount,
  getNoticeStatus,
} from "@/api/hy";
import { logIn } from "@/api/home";
import Vue from "vue";
import md5 from "js-md5";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      show: true,
      dialogFormVisible: false,
      formLabelWidth: "120",
      form: {
        name: "",
        code: "",
      },
      input1: "",
      search: {
        pageNum: 1,
        pageSize: 10,
        keyWord: "",
        userType: 2,
      },
      telephone: "",
      tableData: {
        total: 0,
        list: [],
      },
      passWordIsShow: false,
      addUserDialogIsShow: false,
      userDialogData: {},
      powerDialogIsShow: false,
      powerDialogData: {},
      smsDialogShow: false,
      smsDialogData: {},
      count: 0,
      noticeObj: {},
    };
  },
  components: {
    InputPasswordDialog: () => import("@/components/InputPasswordDialog.vue"),
    AddDialog: () => import("./components/AddDialog"),
    Power: () => import("./components/PowerDialog"),
    SmsDialog: () => import("./components/SmsDialog.vue"),
  },
  mounted() {
    this.getList();
    this.getEnterpriseByEnterpriseIds();
    console.log(process);
  },
  methods: {
    handleSmsDialog(obj) {
      if (!this.beforhandle("personalCenter:personnelStructure:notice")) return;
      let data = {
        enterpriseId: Vue.ls.get(types.enterpriseId),
        userId: obj.userId,
      };
      getNoticeStatus(data).then((res) => {
        this.noticeObj = res.data || {};
        this.$nextTick(() => {
          this.smsDialogShow = true;
          this.smsDialogData = obj;
        });
      });
    },
    dialogFormVisibleClcik() {
      // updateEnterpriseAccount
      let config = {
        telephone: this.form.name,
        smsCode: this.form.code,
      };
      updateEnterpriseAccount(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "您的账号已变更，请重新登录。",
            type: "success",
          });
          this.dialogFormVisible = false;
          // this.layout()
        } else {
          this.$message.error(res.message);
        }
      });
    },
    layout() {
      localStorage.clear();
      // this.isLogins();
      let config = {
        grant_type: "init",
        client_id: "xjcec",
        client_secret: "xjcec_secret",
        scope: "all",
      };
      logIn(config).then((res) => {
        if (res.code == 0) {
          Vue.ls.set(types.ACCESS_TOKEN, res.data.accessToken);
          Vue.ls.set(types.REFRESH_TOKEN, res.data.refreshToken);
          Vue.ls.set(types.checkUkeyWhite, res.data.checkUkeyWhite);
          Vue.ls.set(types.EXPIRES_IN, res.data.expiresTime + "000");
        }
      });
      if (this.$route.path != "/index") {
        this.$router.push("/index");
      } else {
        window.location.reload();
      }
    },
    getCode() {
      //axios请求
      if (!this.form.name) {
        this.$YTmsg("请输入手机号码", "error");
        return false;
      }
      if (!this.$verify.phone(this.form.name)) {
        // this.errorType = 11;
        this.$YTmsg("请填写正确的手机号", "error");
        return false;
      }

      reqSendCode({ phone: this.form.name })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "验证码已发送，请注意查收",
            });

            this.count = 60;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEnterpriseByEnterpriseIds() {
      let id = Vue.ls.get(types.enterpriseId);
      getEnterpriseByEnterpriseId(id).then((res) => {
        this.telephone = res.data.telephone;
      });
    },
    personnelStructure(e) {
      console.log(e);
      let config = {
        password: md5(e),
        type: 2,
      };
      reqCheckPassword(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.dialogFormVisible = true;
        } else {
          this.$confirm(`${res.message}`, "提示", {
            confirmButtonText: "去修改",
            cancelButtonText: "重新输入",
            type: "warning",
          })
            .then(() => {
              this.$router.push({
                path: "/finance",
              });
            })
            .catch(() => {
              this.passWordIsShow = true;
            });
        }
      });
      console.log(e);
    },
    headerCellStyle() {
      return "background-color:#F7F7F7;color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;";
    },
    OffFn(item) {
      console.log(item);
      let data = {
        enableStatus: item.enableStatus,
        updateBy: Vue.ls.get(types.userId),
        userIds: [item.userId],
      };
      this.enableOrDeleteUser(data);
    },
    changeFn() {
      if (!this.beforhandle("personalCenter:personnelStructure:change")) return;
      this.passWordIsShow = true;
    },
    setPower(item) {
      // this.powerDialogData = item
      // this.powerDialogIsShow = true
      if (!this.beforhandle("personalCenter:personnelStructure:setPower"))
        return;
      this.$router.push({
        path: "/personnelStructure/setPower",
        query: {
          userId: item.userId,
        },
      });
    },
    addUser() {
      if (!this.beforhandle("personalCenter:personnelStructure:add")) return;
      this.userDialogData = {};
      this.addUserDialogIsShow = true;
    },
    editUser(item) {
      if (!this.beforhandle("personalCenter:personnelStructure:edit")) return;
      this.userDialogData = item;
      this.addUserDialogIsShow = true;
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    remove(item) {
      if (!this.beforhandle("personalCenter:personnelStructure:del")) return;
      let data = {
        delFlag: 1,
        updateBy: Vue.ls.get(types.userId),
        userIds: [item.userId],
      };
      this.$confirm("是否删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "hy-confirm",
        iconClass: "el-icon-info",
      })
        .then(() => {
          this.enableOrDeleteUser(data);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    enableOrDeleteUser(data) {
      reqEnableOrDeleteUser(data)
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchFn() {
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList();
    },
    getList() {
      reqUserList(this.search)
        .then((ret) => {
          this.tableData.list = ret.code == 0 ? ret.data.list : [];
          this.tableData.total = ret.code == 0 ? ret.data.total : 0;
          console.log(this.tableData.list);
        })
        .catch((err) => {
          this.tableData.list = [];
          this.tableData.total = 0;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.personnelStructure {
  margin: 14px auto;
  padding: 20px 40px;
  background: white;
  padding: 21px;
  border: 1px solid #e5e5e5;
  min-height: 500px;
  &::before,
  &::after {
    display: table;
    content: "";
  }
}
.title {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  .el-button {
    margin-left: 20px;
  }
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .count {
    display: flex;
    align-items: center;
    .el-button {
      margin: 0 45px 0 20px;
    }
    span {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .search-input {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-input {
      width: 300px;
      margin-right: 10px;
    }
    &::v-deep {
      .el-input,
      .el-input__inner,
      .el-button {
        border-radius: 4px;
        height: 34px;
        line-height: 34px;
      }
      .el-input__icon {
        line-height: 34px;
      }
      .el-button {
        background: linear-gradient(90deg, #2079fb, #4cb2fc);
        color: white;
        padding: 0 20px;
      }
    }
  }
}
.table-list {
  min-height: 500px;
  margin-top: 20px;
  .el-table {
    border: 1px solid #e5e5e5;
  }
  &::v-deep {
    thead th {
      background: #f7f7f7;
      .cell {
        font-weight: normal;
        color: #333;
      }
    }
    tbody td .cell {
      padding: 16px;
    }
    .el-table__cell {
      border-bottom: 1px dashed #e5e5e5;
    }
    .el-button--primary {
      background: white;
      border: 1px solid #e5e5e5;
      &:hover {
        color: #409eff;
      }
    }
    .el-button--danger {
      background: white;
      border: 1px solid #f56c6c;
      &:hover {
        color: #f56c6c;
        border: 1px solid #f56c6c;
      }
    }
  }
  .el-pagination {
    text-align: right;
    margin-top: 8px;
  }
}
::v-deep .cell {
  .el-button {
    & + .el-button {
      // margin-top: 5px;
    }
  }
}
::v-deep .el-button--danger.is-plain {
  color: red !important;
}
::v-deep .el-button--primary.is-plain {
  color: #409eff;
}
.code-btn {
  width: 100px;
  height: 34px;
  background: linear-gradient(90deg, #2079fb, #4cb2fc);
  border-radius: 4px;
  z-index: 222;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 9px;
  margin-left: 8px;
}
</style>
